import { gsap } from 'gsap';

function bonus() {
  this.BonusFirst = gsap.timeline({ paused: true });
  this.BonusSecond = gsap.timeline({ paused: true });

  this.BonusFirst
    .to(this.bonusesStars, {
      rotate: -14,
      repeat: 12,
      duration: 0.05,
      clearProps: 'all',
    })
    .to(this.bonusStarOne, {
      ease: 'power4.out',
      duration: 0.8,
      x: '-16em',
      scale: 0.7,
    })
    .to(this.bonusStarTwo, {
      ease: 'power4.out',
      duration: 0.8,
      x: '16em',
      scale: 0.7,
    }, '<')
    .to(this.titleBonus, {
      ease: 'power4.inOut',
      duration: 0.8,
      autoAlpha: 1,
      scale: 1,
    }, '<')
    .to(this.bonusesButtons, {
      ease: 'power4.inOut',
      duration: 0.8,
      scale: 1,
    }, '<');

  return new Promise((resolve) => {
    this.BonusSecond
      .to(this.bonusStarOne, {
        ease: 'power4.out',
        duration: 0.8,
        x: '-26em',
        autoAlpha: 0,
      })
      .to(this.bonusStarTwo, {
        ease: 'power4.out',
        duration: 0.8,
        x: '26em',
        autoAlpha: 0,
      }, '<')
      .to(this.bonuses, {
        ease: 'sine.inOut',
        duration: 0.8,
        '--hidden-width': '100%',
        onComplete: () => {
          if (this.wasSpin) {
            resolve('finalWheelAction');
          }
        },
      });
  });
}

export default bonus;
