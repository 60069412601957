import { gsap } from 'gsap';
import bonus from './bonus';
import spin from './spin';

class Wheel {
  constructor({ repeat = 3 } = {}) {
    this.spinButton = document.getElementById('spin-button');
    this.wheel = document.querySelector('.wheel');
    this.wheelDrum = this.wheel.querySelector('.wheel__drum');
    this.wheelBlur = this.wheel.querySelector('.wheel__blur');
    this.wheelBlackout = this.wheel.querySelector('.wheel__blackout');
    this.titleBonus = document.querySelector('.title-bonus');
    this.bonusesStars = document.querySelector('.bonuses__stars');
    this.bonusStarOne = document.querySelector('.bonus-star-one');
    this.bonusStarTwo = document.querySelector('.bonus-star-two');
    this.bonusesButtons = document.querySelector('.bonuses__buttons');
    this.bonuses = document.querySelector('.bonuses');

    this.spinRepeat = repeat;
    this.wasSpin = true;
  }

  spinAction() {
    this.spinButton.onclick = () => {
      this.wasSpin = !this.wasSpin;
      this.tlWheelSpin.restart();

      spin.call(this, this.wasSpin);
    };
  }

  async ended() {
    return await bonus.call(this) === 'finalWheelAction' ? 'wheelEnded' : null;
  }

  init() {
    spin.call(this);
    bonus.call(this).then();

    this.spinAction();
  }

  destroy() {
    gsap.killTweensOf([
      this.wheelDrum,
      this.wheelBlur,
      this.wheelBlackout,
      this.bonusesStars,
      this.bonusStarOne,
      this.bonusStarTwo,
      this.titleBonus,
      this.bonusesButtons,
    ]);
  }
}

export default Wheel;
