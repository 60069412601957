import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

function spin() {
  this.tlWheelSpin = gsap.timeline({ paused: true });
  this.tlWheelSpare = gsap.timeline({ paused: true });

  this.tlWheelSpin
    .to(this.wheelDrum, {
      ease: 'sine.in',
      rotation: this.wasSpin ? '+=540' : '+=720',
      duration: 1,
      onStart: () => {
        this.tlWheelSpare.restart();
        this.spinButton.classList.add('spinning');
      },
    })
    .to(this.wheelDrum, {
      clearProps: 'rotation', // Очищаем rotate(), чтобы не приращивать при каждой итерации
      ease: 'none',
      rotation: '+=720',
      duration: 0.5,
      repeat: this.spinRepeat,
    })
    .to(this.wheelDrum, {
      ease: CustomEase.create('custom', 'M0,0 C0.11,0.494 0.136,0.67 0.318,0.852 0.626,1.16 0.853,0.989 1,1'),
      rotation: this.wasSpin ? '+=720' : '+=540',
      duration: 3,
    });

  const spinDuration = this.tlWheelSpin.totalDuration();

  this.tlWheelSpare
    .to(this.wheelBlur, {
      backdropFilter: 'blur(35px)',
      duration: 1,
      ease: 'power3.in',
    })
    .to(this.wheelBlur, {
      backdropFilter: 'blur(0px)',
      duration: 1,
      ease: 'power2.out',
    }, `+=${spinDuration - 4}`)
    .to(this.wheelBlackout, {
      autoAlpha: 0.75,
      duration: 0.5,
      ease: 'power2.inOut',
      onStart: () => (this.wasSpin ? this.BonusSecond.restart() : this.BonusFirst.restart()),
    }, '+=1.75')
    .to(this.wheelBlackout, {
      autoAlpha: 0,
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete: () => {
        this.spinButton.classList.remove('spinning');

        if (!this.wasSpin) {
          this.spinButton.classList.add('first-spin-end');
        } else {
          this.spinButton.classList.add('second-spin-end');
        }
      },
    }, '+=1');
}

export default spin;
