import { gsap } from 'gsap';
import MediaTrigger from 'media-trigger';

class Toys {
  constructor() {
    this.toys = gsap.utils.toArray('.toy');
    this.animation();
  }

  static createTrigger(media, entry, exit, change) {
    return new MediaTrigger({
      media,
      entry,
      exit,
      change,
    });
  }

  animation() {
    this.tlToysD = gsap.timeline({ paused: true });
    this.tlToysM = gsap.timeline({ paused: true });

    this.tlToysD
      .to(this.toys, {
        ease: 'power1.inOut',
        x: 'random(-20, 20)',
        y: 'random(-20, 20)',
        duration: 4,
        repeat: -1,
        yoyo: true,
        repeatRefresh: true,
      });

    this.tlToysM
      .to(this.toys, {
        ease: 'power1.inOut',
        x: 'random(-7, 7)',
        y: 'random(-7, 7)',
        duration: 4,
        repeat: -1,
        yoyo: true,
        repeatRefresh: true,
      });
  }

  init() {
    Toys.createTrigger(
      '(min-width: 1200px)',
      () => {
        this.tlToysD.restart();
        this.tlToysM.pause();
      },
      () => {
        this.tlToysM.restart();
        this.tlToysD.pause();
      },
    );
  }

  destroy() {
    gsap.killTweensOf([
      this.toys,
    ]);
  }
}

export default Toys;
