import Wheel from './utils/wheel';
import Toys from './utils/Toys';

const wheel = new Wheel({ repeat: 3 });
const toys = new Toys();

const start = async () => {
  toys.init();
  wheel.init();

  const wheelEnded = await wheel.ended() === 'wheelEnded';

  if (wheelEnded) {
    console.log('wheelEnded');
  }
};

start().then();
